<template>
	<div class="dashboard">
		<v-container fluid style="padding-top: 0px; padding-bottom: 0px">
			<v-row class="dashboard-view d-sm-flex d-block">
				<div class="d-sm-block d-none">
					<app-sidemenu></app-sidemenu>
				</div>
				<div v-if="showMenu" class="d-sm-none d-block sidemenu" @click="showSideMenu()">
					<app-sidemenu></app-sidemenu>
				</div>
				<v-col class="pa-0 resp-width">
					<v-row no-gutters>
						<v-col class="search-bar pa-0 d-flex align-center pr-5 pt-2" cols="12">
							<app-search></app-search>
							<v-col cols="1" class="d-sm-none d-block text-end mr-5">
								<i class="mdi mdi-sort-variant" style="font-size: 35px; color: #2974ff" @click="showSideMenu()" />
							</v-col>
						</v-col>
						<v-col
							v-if="this.$store.state.auth.user_data.account_id === false"
							cols="12"
							class="d-flex justify-end pr-2"
							style="margin-bottom: -80px"
						>
							<v-alert border="bottom" colored-border type="warning" elevation="2" dismissible
								>Setup your bank account information, into Settings menu Account Detail & Transactions tab.</v-alert
							>
						</v-col>

						<v-col class="content-div" cols="12">
							<transition name="fade" mode="out-in">
								<router-view></router-view>
							</transition>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<app-alert></app-alert>
		</v-container>
	</div>
</template>

<script>
const Sidemenu = () => import('./Sidemenu')
const Search = () => import('./Search')
require('../../scss/bid.scss')
require('../../scss/list.scss')
import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'

export default {
	components: {
		AppSidemenu: Sidemenu,
		AppSearch: Search,
	},

	data() {
		return {
			showMenu: false,
			hidden: '',
			visibilityChange: '',
		}
	},

	async created() {
		if (this.$router.currentRoute.path === '/') {
			this.$router.push({ path: 'home' })
		}
	},

	async mounted() {
		setTimeout(async () => {
			let docRef = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc(`${this.$store.state.auth.user_data.company_id}DC`)
			docRef.update('isUserOnline', true)
		}, 2000)

		this.visiblity()

		// Warn if the browser doesn't support addEventListener or the Page Visibility API
		if (typeof document.addEventListener === 'undefined' || this.hidden === undefined) {
			console.log('This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.')
		} else {
			// Handle page visibility change
			document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false)
		}
	},

	watch: {
		$route(to, from) {
			if (this.$router.currentRoute.path === '/') {
				this.$router.push({ path: 'home' })
			}
		},
	},
	methods: {
		showSideMenu() {
			this.showMenu = !this.showMenu
		},

		visiblity() {
			if (typeof document.hidden !== 'undefined') {
				this.hidden = 'hidden'
				this.visibilityChange = 'visibilitychange'
			} else if (typeof document.msHidden !== 'undefined') {
				hidden = 'msHidden'
				visibilityChange = 'msvisibilitychange'
			} else if (typeof document.webkitHidden !== 'undefined') {
				hidden = 'webkitHidden'
				visibilityChange = 'webkitvisibilitychange'
			}
		},

		async handleVisibilityChange() {
			this.visiblity()

			if (document[this.hidden]) {
				let docRef = await firebase
					.firestore()
					.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
					.doc(`${this.$store.state.auth.user_data.company_id}DC`)
				docRef.update('isUserOnline', false)
			} else {
				let docRef = await firebase
					.firestore()
					.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
					.doc(`${this.$store.state.auth.user_data.company_id}DC`)
				docRef.update('isUserOnline', true)
			}
		},
	},
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.content-div {
	min-width: 100%;
	max-width: 100%;
	margin-top: -80px;
	margin-bottom: -20px;
	// padding-top: 80px !important;
	padding-bottom: 20px;
	min-height: 100vh;
	max-height: 100vh;
	overflow-y: scroll;
	scroll-margin-top: 500px !important;
}

.search-bar {
	min-height: 80px !important;
	box-shadow: 0 8px 24px 0 rgba(18, 112, 171, 0.08) !important;
	z-index: 10;
	background-color: #fbfbfb;
}
.v-alert {
	z-index: 12;
	height: 56px;
}
@media only screen and (max-width: 1024px) {
	.resp-width {
		width: 50%;
	}
}
@media only screen and (max-width: 600px) {
	.dashboard-view {
		// flex-direction: column;
		display: block;
	}
	.resp-width {
		width: 100%;
	}
	.sidemenu {
		position: absolute;
		z-index: 111;
	}
	.v-alert {
		z-index: 12;
		height: fit-content;
	}
}
</style>
